import { GLTFAsset } from "scene-components/gltf-asset";
import { Text, LineProps } from "@react-three/drei";
import CRANE from "assets/crane.glb";
import HOOK from "assets/hook.glb";
import {
  useRigidBody,
  BodyType,
  BodyActivationState,
  ShapeType,
  ShapeFit,
  useSoftBody,
  SoftBodyType,
} from "use-ammojs";
import { Vector3, Object3D, Vector3Tuple, Vector2 } from "three";
import { useEffect, useMemo, MutableRefObject, useState } from "react";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "./line-material";

export function Crane() {
  const [hookRef, hookApi] = useRigidBody({
    bodyType: BodyType.DYNAMIC,
    shapeType: ShapeType.BOX,
    activationState: BodyActivationState.DISABLE_DEACTIVATION,
    mass: 10,
    shapeConfig: {
      fit: ShapeFit.MANUAL,
      halfExtents: new Vector3(8, 3, 0.6),
      offset: new Vector3(0, 3, 0),
    },
    angularDamping: 0.6,
  });

  const start: Vector3Tuple = [0, 10, 0];
  const segments = 8;

  // const ref = useRef<any>();

  const [ref] = useSoftBody({
    type: SoftBodyType.ROPE,

    anchors: [
      {
        nodeIndex: 0,
        worldPosition: new Vector3(start[0], start[1], start[2]),
      },
      {
        nodeIndex: segments - 1,
        rigidBodyRef: hookRef,
        disableCollisionBetweenLinkedBodies: true,
        localOffset: new Vector3(0, 6, 0),
      },
    ],
  });

  // useSingleBodyConstraint({
  //   type: ConstraintType.GENERIC_6_DOF,
  //   frameInB: {
  //     position: new Vector3(0, 10, 0),
  //     rotation: new Quaternion(0, 0, 0, 1),
  //   },
  //   bodyARef: hookRef,
  //   useLinearReferenceFrameA: false,
  // });

  useEffect(() => {
    hookApi.applyImpulse(new Vector3(1, 0, 3).multiplyScalar(10));
  }, []);

  return (
    <>
      <group position-y={10} rotation-y={-90}>
        <GLTFAsset gltfUrl={CRANE} />
      </group>

      <Rope start={start} end={[0, 0, 0]} segments={8} ropeRef={ref} />

      <group
        ref={hookRef}
        onClick={(ev) => {
          const localImpulse = ev.ray.direction
            .clone()
            .applyQuaternion(
              (hookRef.current! as Object3D).quaternion.clone().invert()
            );
          const local = hookRef.current!.worldToLocal(ev.point.clone());
          hookApi.applyImpulse(localImpulse.multiplyScalar(50), local);
        }}
      >
        <group position-y={6}>
          <GLTFAsset gltfUrl={HOOK} />

          <Text
            color="black"
            fontSize={2}
            position-y={-4}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            fillOpacity={0}
            strokeWidth={"2.5%"}
            strokeColor="black"
            lineHeight={1}
            textAlign="center"
          >
            {"UNDER\nCONSTRUCTION"}
          </Text>
        </group>
      </group>
    </>
  );
}

function Rope({
  start,
  end,
  segments,
  ropeRef,
  ...props
}: {
  start: [number, number, number];
  end: [number, number, number];
  segments: number;
  ropeRef: MutableRefObject<Object3D | undefined>;
} & Omit<LineProps, "points">) {
  const [line2] = useState(() => new Line2());
  const [lineMaterial] = useState(() => new LineMaterial());
  const [resolution] = useState(() => new Vector2(512, 512));

  const points = useMemo(() => {
    const p: Vector3[] = [];

    const s = new Vector3(start[0], start[1], start[2]);
    const e = new Vector3(end[0], end[1], end[2]);

    for (let i = 0; i <= segments; i++) {
      const interpolated = new Vector3().lerpVectors(s, e, i / segments);

      p.push(interpolated);
    }

    return p;
  }, [...start, ...end]);

  const geometry = useMemo(() => {
    const geom = new LineGeometry();

    geom.setPositions(points.map((p) => p.toArray()).flat());
    geom.setColors(points.map((p) => [0, 0, 0]).flat());
    return geom;
  }, [points]);

  console.log(geometry);

  setTimeout(() => {
    console.log(ropeRef);
  }, 100);

  return (
    <>
      <primitive object={line2} ref={ropeRef}>
        <primitive object={geometry} attach="geometry" />
        <primitive
          object={lineMaterial}
          attach="material"
          resolution={resolution}
          color="black"
        />
      </primitive>
    </>
  );
}
