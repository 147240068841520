import { Scene } from "scene-components/scene";
import { Canvas } from "@react-three/fiber";

export function HomePage() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Canvas>
        <Scene />
      </Canvas>
    </div>
  );
}
