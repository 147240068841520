import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "components/error-boundary/error-boundary";
import { HomePage } from "pages/home-page/home-page";

export function App() {
  return (
    <ErrorBoundary>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route component={HomePage} />
          </Switch>
        </Router>
    </ErrorBoundary>
  );
}
