import { useThree } from "@react-three/fiber";
import React, { useEffect, useState } from "react";
import { Physics, ShapeType, useRigidBody } from "use-ammojs";
import { Ground } from "scene-components/ground";
import { BasicLighting } from "entities/scene/basic-lighting";
import { OrbitControls, Sky, Box } from "@react-three/drei";
import { Vector3 } from "three";
import { Crane } from "scene-components/crane";

const SUN_POSITION = new Vector3(2, 4, 5);

export function Scene() {
  const { camera } = useThree();

  useEffect(() => {
    camera.far = 4000;
    camera.position.y = 5;
    camera.position.x = 0;
    camera.position.z = 30;
    camera.updateProjectionMatrix();
  }, [camera]);

  return (
    <Physics drawDebug={false} fixedTimeStep={1 / 120}>
      <BasicLighting />
      <Ground />
      <OrbitControls />

      <Crane />

      <Sky
        sunPosition={SUN_POSITION}
        inclination={0}
        azimuth={0.25}
        distance={4000}
      />
    </Physics>
  );
}

function BoxSpawner() {
  const [boxes, setBoxes] = useState<number[]>([]);

  useEffect(() => {
    const timeout = setInterval(() => {
      setBoxes((prevBoxes) => [...prevBoxes, 1]);
    }, 10);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <>
      {boxes.map((box, index) => (
        <PhysicalBox key={index} />
      ))}
    </>
  );
}

function PhysicalBox() {
  const [ref] = useRigidBody(() => ({
    mass: 1,
    position: [0, 10, 0],
    shapeType: ShapeType.BOX,
  }));

  return (
    <Box ref={ref}>
      <meshPhysicalMaterial attach="material" color="red" />
    </Box>
  );
}
