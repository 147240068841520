import { Box } from "@react-three/drei";
import { useRigidBody, BodyType, ShapeType } from "use-ammojs";

export function Ground() {
  const [groundRef] = useRigidBody(() => ({
    bodyType: BodyType.STATIC,
    shapeType: ShapeType.BOX,
    position: [0, -40, 0],
  }));

  return (
    <Box ref={groundRef} args={[200, 0.5, 200]} receiveShadow>
      <meshPhysicalMaterial attach="material" color="grey" />
    </Box>
  );
}
