import { createContext, useContext } from "react";

export const ErrorContext = createContext<
  | {
      handleErrorWithPage: (err: Error) => void;
    }
  | undefined
>(undefined);

export function useErrorHandler() {
  const hooks = useContext(ErrorContext);

  if (!hooks) {
    throw new Error("Error Boundary required");
  }

  return hooks;
}
