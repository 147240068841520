import { ErrorContext } from "components/error-boundary/error-context";
import { Component } from "react";

export class ErrorBoundary extends Component<{}, { error: Error | false }> {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // Sentry.captureException(error, { extra: errorInfo });
    //logErrorToMyService(error, errorInfo);
  }

  handleErrorWithPage = (error: Error) => {
    console.error(error, (error as any).response);
    this.setState({ error });
  };

  render() {
    if (this.state.error) {
      const additionalData = (this.state.error as any).response?.data;

      return (
        <>
          <h1>Something went wrong.</h1>
          <p>{this.state.error.message}</p>
          <p>{additionalData}</p>
        </>
      );
    }

    return (
      <ErrorContext.Provider
        value={{ handleErrorWithPage: this.handleErrorWithPage }}
      >
        {this.props.children}
      </ErrorContext.Provider>
    );
  }
}
