import { PropsWithChildren, ReactNode, useMemo, useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Object3D, Mesh } from "three";
import { BodyType, ShapeType, usePhysics } from "use-ammojs";

/**
 * Renders children as siblings once the model is laoded
 */
export function GLTFAsset({
  gltfUrl,
  onLoad,
  enablePhysics = false,
  loader,
  children,
}: PropsWithChildren<{
  gltfUrl: string;
  onLoad?(gltf): void;
  enablePhysics?: boolean;
  loader?: ReactNode;
}>): JSX.Element {
  const [gltf, set] = useState<any>();
  useEffect(
    () =>
      new GLTFLoader().load(gltfUrl, (model) => {
        set(model);

        console.log("gltf loaded", model);

        model.scene.traverse((node) => {
          if ((node as Mesh).isMesh) {
            node.castShadow = true;
            node.receiveShadow = true;
          }
        });

        if (onLoad) {
          onLoad(model);
        }
      }),
    [gltfUrl]
  );

  return gltf ? (
    <>
      <primitive object={gltf.scene} />
      {enablePhysics && <GLTFPhysics object={gltf.scene as any} />}
      {children}
    </>
  ) : (
    <>{loader || null}</>
  );
}

function GLTFPhysics({ object }: { object: Object3D }) {
  usePhysics(
    () => ({
      shapeType: ShapeType.MESH,
      type: BodyType.STATIC,
    }),
    object
  );

  return null;
}
