export function BasicLighting() {
  return (
    <>
      <ambientLight intensity={0.8} />
      <spotLight
        intensity={0.5}
        position={[40, 50, 50]}
        shadow-bias={-0.00005}
        penumbra={1}
        angle={Math.PI / 6}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        castShadow
      />
    </>
  );
}
